* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  text-shadow: 0px 0px 10px black;
}

:root {
  --color-1: #011627;
  --color-2: #112240;
  --color-3: #a1e4f1;
  --color-4: #b4e9f4;
  --color-5: #f72585;
}

body {
  background-color: var(--color-1);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: Montserrat;
}

#root {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.2s ease-in;
}

/* fade in classes */
.fade-in {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

.fade-in.appear {
  opacity: 1;
}

/* flex box classes */
.flex {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexJustifyAndAlignCenter {
  justify-content: center;
  align-items: center;
}

.flexAlignCenter {
  align-items: center;
}

.flexJustifyCenter {
  justify-content: center;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: var(--color-2);
}

.HTMLTags {
  font-size: 32px;
  color: var(--color-5);
  font-weight: 400;
  margin: 1vh 0px;
  text-shadow: 0px 0px 5px var(--color-5);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

@media (max-width: 720px) {
  .HTMLTags {
    font-size: 22px;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
}

.socialLinksDiv {
  align-items: center;
  margin: 0 20px 0 auto;
  gap: 20px;
  z-index: 10;
}

.socialLinksDiv h1 {
  position: relative;
  right: 100px;
  color: var(--color-3);
  font-weight: 400;
  font-size: 20px;
}

.socialLinksDiv h1::after {
  position: relative;
  left: 105px;
  bottom: 13px;
  content: "";
  display: block;
  width: 100px;
  height: 4px;
  background-color: var(--color-3);
}

.linkSVG {
  height: 40px;
  width: 40px;
  fill: var(--color-3);
  transition: all 100ms ease-in;
}

.linkSVG:hover {
  fill: var(--color-5);
  transform: scale(1.2);
}

@media (max-width: 900px) {
  .socialLinksDiv {
    display: none;
  }
} 

.skills {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 200px 0px;
}

.container {
  width: calc(100% - 20px);
  max-width: 1000px;
  display: flex;
  flex-direction: column;
}

.honeycomb-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 0;
}

.honeycomb-row {
  display: flex;
  filter: drop-shadow(0px 0px 3px var(--color-3));
}

.honeycomb-row .hexagon {
  position: relative;
  width: 150px;
  height: 175px;
  margin: -15px 7.5px;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  background-color: var(--color-2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.honeycomb-row .hexagon img {
  width: 110px;
  height: 130px;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  background-color: var(--color-3);
  padding: 10px;
}

@media (max-width: 600px) {
  .honeycomb-row .hexagon {
    width: 110px;
    height: 125px;
    margin: -10px 7.5px;
  }

  .honeycomb-row .hexagon img {
    width: 90px;
    height: 100px;
  }
}

.jumpLinksDiv {
  margin-left: 20px;
  width: fit-content;
  z-index: 10;
}

.jumpLinksDiv ol {
  gap: 35px;
  list-style: none;
}

.jumpLinksDiv ol li {
  counter-increment: item 1;
  cursor: pointer;
  transition: color 100ms ease-in;
  font-size: 22px;
}

.jumpLinksDiv ol li a {
  text-decoration: none;
  color: var(--color-3);
  transition: color 100ms ease-in;
  text-shadow: 2px 2px 5px black;
}

.jumpLinksDiv ol li a:hover {
  color: var(--color-5);
}

.jumpLinksDiv ol li::before {
  content: "0" counter(item) ".";
  color: var(--color-5);
  margin-right: 6px;
}

@media (max-width: 900px) {
  .jumpLinksDiv {
    display: none;
  }
}

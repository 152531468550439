.guitar-container {
  margin: 100px 0px;
}

.guitar {
  max-width: 1000px;
  width: calc(100% - 20px);
}

.guitar .grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px 0px;
}

.guitar .grid .card {
  position: relative;
  background-color: var(--color-2);
  width: 100%;
  color: var(--color-3);
  box-shadow: 0px 0px 20px black;
  min-width: 0;
  opacity: 0.8;
  border-radius: 10px;
  overflow: hidden;
  transition: opacity ease-in 250ms;
}

.guitar .grid .card:hover {
  opacity: 1 !important;
}

.guitar .grid .card video {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.guitar .grid .card span {
  width: 90%;
  margin: 12px 0px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.guitar .grid .card svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #e8e8e8;
  height: 100px;
  width: 100px;
}

@media (max-width: 800px) {
  .guitar .grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 500px) {
  .guitar .grid {
    grid-template-columns: 1fr;
  }
}

#forthPage {
  position: relative;
  height: fit-content;
  width: 100vw;
}

.otherSkillsContainer {
  margin: 80px 0vh;
  position: relative;
  width: 95%;
  max-width: 1000px;
  height: fit-content;
}

.otherSkillsDiv {
  position: relative;
  transform: translateX(-50%);
  width: 100%;
  max-width: 900px;
  height: 400px;
  background-color: var(--color-2);
  overflow: hidden;
  opacity: 0;
  transition: all 500ms ease-in;
  display: flex;
  box-shadow: 0px 0px 20px black;
}

.otherSkillsTextDiv {
  padding: 30px;
  box-sizing: border-box;
  width: calc(100% - 400px);
  gap: 20px;
  height: 100%;
}

.otherSkillsTextDiv h1 {
  color: var(--color-4);
  font-size: 30px;
}

.otherSkillsTextDiv p {
  font-size: 20px;
  color: var(--color-3);
}

.otherSkillsDiv img {
  width: 400px;
  aspect-ratio: 1/1;
}

.otherSkillsDiv iframe {
  position: relative;
  top: -54px;
  width: 400px;
  height: 454px;
  margin-bottom: -54px;
}

.otherSkillsDiv.active {
  opacity: 1;
}

.otherSkillsDiv.active {
  left: 50% !important;
}

.otherSkills__video {
  width: 400px;
  height: 400px;
}

@media (max-width: 1200px) {
  .otherSkillsDiv {
    width: 100%;
  }
}

@media (max-width: 780px) {
  .otherSkillsContainer {
    width: 95%;
  }

  .otherSkillsDiv {
    flex-direction: column;
    height: fit-content;
  }
  
  .otherSkillsTextDiv {
    order: 2;
    width: 100%;
    padding: 15px 10px;
    gap: 5px;
  }

  .otherSkillsTextDiv h1 {
    font-size: 24px;
  }

  .otherSkillsTextDiv p {
    font-size: 16px;
  }

  .otherSkills__video {
    order: 1;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }

  .otherSkillsDiv img {
    width: 100%;
  }
}

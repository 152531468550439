.navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 80px;
    z-index: 10;
    align-items: center;
    transition: all 200ms ease-in;
}

.navbar img {
    height: 70%;
    position: relative;
    display: none;
}

.navbar__menu {
    margin-left: auto;
    fill: white;
    height: 70%;
    aspect-ratio: 1/1;
    display: none;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in;
}

.navbar__menu.active {
    transform: rotate(-90deg);
    fill: var(--color-5);
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 10;
    gap: 25px;
    animation: fade-in 400ms ease-in;
    padding: 10px;
}

@keyframes fade-in {
    from {
        opacity: 0;
    } to {
        opacity: 0.98;
    }
}

@keyframes fade-out {
    from {
        opacity: 0.98;
    } to {
        opacity: 0;
    }
}

.menu__jumps {
    text-decoration: none;
    color: var(--color-2);
    font-weight: 700;
    font-size: 30px;
    transition: all 200ms ease-in;
    text-align: center;
}

.menu__jumps:hover {
    transform: scale(1.1);
    color: var(--color-5);
}

.menu__socials {
    position: absolute;
    bottom: 10px;
    width: 80%;
    justify-content: center;
    gap: 20px;
}

.menu__socials svg {
    height: 50px;
    width: 50px;
    fill: var(--color-2);
}

@media (max-width: 900px) {
    .navbar img {
        display: block;
    }

    .navbar__menu {
        display: flex;
    }

    .navbar {
        position: absolute;
        top: 0px;
        height: 55px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
    }
}
#secondPage {
  position: relative;
  height: fit-content;
  width: 100vw;
}

.projectsContainer {
  margin: 100px 0vh;
  width: 100%;
}

.project-swiper {
  margin: 10px 0px !important;
  overflow: visible !important;
}

.project-swiper .swiper-slide {
  width: 95%;
  max-width: 900px;
  height: 500px !important;
  border-radius: 10px !important;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  background-color: var(--color-2);
}

.project-cards {
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 100ms ease-in, opacity 500ms ease-in;
  padding: 20px 40px;
  text-align: center;
  color: var(--color-4);
  overflow-y: auto;
  border-radius: 10px;
}

.project-cards h1 {
  margin-bottom: 10px;
  color: var(--color-4);
  font-size: 25px;
  transition: color 250ms ease-in;
}

.project-cards li {
  -size: 18px;
}

.project-cards iframe {
  width: 80%;
  margin: 0 auto;
  aspect-ratio: 16/9;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
}

.project-cards img {
  width: 80%;
  border-radius: 10px;
  margin: 20px auto 0 auto;
}

.projectSVG {
  position: absolute;
  right: 6vh;
  height: 90%;
  opacity: 0.2;
  z-index: -1;
}
.project-cards::-webkit-scrollbar {
  width: 8px;
}

.project-cards::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--color-3);
}

.project-cards > .title {
  color: var(--color-5);
  margin: 0px 0 10px 0;
  font-size: 30px;
}

.project-cards > p {
  font-size: 18px;
}

.project-cards > .title svg {
  fill: var(--color-4);
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.project-cards > .title svg:hover {
  fill: var(--color-5);
}

.project-cards li {
  margin-bottom: 4px;
}

.language-coverage {
  max-width: 500px;
  width: 98%;
  margin: 30px 0;
}

.language-coverage-bar {
  height: 15px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  transform: rotateY(180deg);
  margin-bottom: 10px;
  box-shadow: 0px 0px 10px black;
}

.language-coverage-bar div {
  box-shadow: -1px 0px 10px black;
}

.language-coverage-text {
  width: 100%;
  column-gap: 20px;
  row-gap: 7px;
  flex-wrap: wrap;
  color: white;
  font-size: 15px !important;
}

.language-coverage-text div > div {
  height: 22px;
  width: 22px;
  border-radius: 100%;
  margin-right: 7px;
}

@media (max-width: 800px) {
  #projectsContainer {
    margin: 10vh 0px;
    width: 95%;
  }

  .project-swiper .swiper-slide {
    width: 95%;
  }

  .project-cards {
    width: 100%;
    padding: 10px;
  }

  .project-cards > .title {
    font-size: 22px;
  }

  .project-cards h1 {
    font-size: 18px;
  }

  .project-cards > p {
    width: fit-content;
    font-size: 14px;
  }

  .project-cards li {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .language-coverage-text {
    row-gap: 4px;
    column-gap: 10px;
    font-size: 13px !important;
  }

  .project-cards iframe,
  .project-cards img {
    width: 100%;
  }
}
